<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-lg-5 col-md-12">
        <div class="welcome-text">
          <h1>{{ $t('reset-password.text-1') }}</h1>
          <div class="description">
            <p class="pt-3">{{ $t('reset-password.text-2') }}</p>
            <div class="form-group py-4">
              <form-group :field="$v.iin" :label="$t('profile.iin-label')" name="iin">
                <el-input
                    class="iin-recover"
                    maxlength="12"
                    :placeholder="$t('profile.iin-label')"
                    v-model="iin"
                    v-mask="'############'"
                    @change="$v.iin.$touch()"
                    autocomplete="off">
                </el-input>
              </form-group>
            </div>
            <div class="form-group pt-1">
              <el-button type="primary" @click="getUser">{{ $t('reset-password.next-btn') }}</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-12 py-5">
        <img src="/images/signin-bg.svg" width="610" style="max-width: 100%;">
      </div>
    </div>
  </div>
</template>
<script>

import VueMask from 'v-mask'
import Vue from "vue";
import {required, minLength} from 'vuelidate/lib/validators'

export default {
  name: 'Login',
  beforeCreate() {
    Vue.use(VueMask);
  },
  components: {

  },
  data() {
    return {
      method: 'email',
      iin: '',
    }
  },
  methods: {
    next() {
      this.$router.push('/forget-password/' + this.method);
    },
    getUser() {
      this.$http.post(window.API_ROOT + '/api/infobyiin', {
        iin: this.iin,
      })
          .then((res) => {
            const data = res.body
            data.iin = this.iin
            localStorage.setItem('user-recovery', JSON.stringify(data))
            this.$router.push('/forget-password');
          })
          .catch((e) => {
            console.log(e)
          })

    }
  },
  validations: {
    iin: {
      required: false,
      minLength: minLength(12)
    },
  }
}
</script>

<style>
.el-radio {
  display: flex;
}

.description {
  max-width: 350px;
}

.welcome-text .help-block.text-danger {
  font-size: 16px;
}

</style>